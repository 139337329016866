<template>
  <div class="container">
    <div class="h-100 row">
      <div class="mx-auto my-auto col-12 col-md-10 bg-white rounded-lg">
        <div class="row">
          <div class="col-md-5 p-0">
            <div class="image-side-blur"></div>
            <div class="bg-text">
              <h1 class="font-weight-light text-white">CABRERA IS IN THE DETAILS</h1>
              <p class="text-white mb-0">
                They have sent you an invitation.
                If you are not a member, please register.
              </p>
            </div>
          </div>
          <div class="col-md-7 py-10 px-11">
            <img src="../assets/image/logo.jpg" width="300" heigth="150">
            <label class="h5 font-weight-normal mt-5 mb-5">Reset Password</label>
            <el-form
              :model="form"
              ref="form"
              :hide-required-asterisk="true"
              :rules="rules"
              @submit.native.prevent
            >
              <el-form-item
                :label="$t('register.password')"
                prop="password"
                :rules="[{ required: true, message: 'The field is required'}]"
              >
                <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item :label="$t('register.confirm')" prop="checkPass">
                <el-input type="password" v-model="form.checkPass" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item class="mb-0">
                <el-button
                  class="btn-big"
                  type="primary"
                  @click="submitForm('form')"
                >{{$t('register.register')}}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from "@/services/api/auth";

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === null) {
        callback(new Error("Please input the password again"));
      } else if (value !== this.form.password) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        password: null,
        checkPass: null,
        token: this.$route.params.token
      },
      rules: {
        checkPass: [{ validator: validatePass }]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          auth.reset(this.form).then(() => {
            this.$message({
              message: "Congrats, this is a success message.",
              type: "success",
              customClass: "message-success"
            });
            this.$router.push('/') 
          });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss">
.show-pwd {
  top: 4px;
  right: 15px;
}

.image-side-blur{
  background: url(../assets/image/bg.jpg) no-repeat top;
  background-size: cover;
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  padding: 70px 40px;
  height: 100%;
  width: 100%;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.bg-text {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
</style>
